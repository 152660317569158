import React from 'react'
import { Link } from 'gatsby'
import { TweenLite } from 'gsap'
import scrollTo from '../../node_modules/gsap/ScrollToPlugin'

import '../css/button.scss'

export default class CTA extends React.Component {
  render() {
    return (
      <Link className={ `button ${ this.props.extraClass }`} to={this.props.link}>{this.props.text}</Link>
    )
  }
}

CTA.defaultProps = {
  link: '/#0',
  text: 'Start A Project',
}
