import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/layout';
import CTA from '../../components/button';

const Page = props => (
  <Layout>
    <div>
      <Row className="heading" middle="xs" center="xs">
        <Col xs={10}>
          <h2 className="section-heading scroll">VectorGlitch</h2>
          <h3>60 Graphic Styles for Illustrator</h3>
        </Col>
        <Col xs={10}>
          <Img fluid={props.data.cover.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.before.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.repeating.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.instructions.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.swatches.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.shred.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.pure.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.bonus.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.thanks.childImageSharp.fluid} />
          <br />
        </Col>
        <Col xs={10} className="start-xs">
          <p>I'm very excited to introduce VectorGlitch for Illustrator</p>
          <p>Instantly add a glitched style to your vector art with 60 pure-vector, graphic styles.</p>
          <p>
            Why not Photoshop?
            <br />
            There are a handful of Photoshop glitch effects and actions out there, and they do a good enough job...on
            photos. These actions and effects treat your type and symbols exactly like photos and use raster-based
            distortion. VectorGlitch's styles are purely vector, infinitely scalable and remain editable after being
            applied.
          </p>
          <p>
            Layer fonts?
            <br />
            Yeah they're pretty cool, but limited by exactly that - the layers and the font. VectorGlitch isn't
            dependent on either. Meaning you're free to use any font you like with any of the 60 base styles. The
            possibilities are endless.
          </p>
          <p>
            Before VectorGlitch, I was dragging out lines, dividing my objects in the pathfinder and moving the pieces
            around one by one until I eventually got the glitchy look I wanted. Now I can achieve that same look in a
            single click and the art remains live and editable. No flattening, merging or outlining necessary.
          </p>
          <p>Give your grunge textures a break and add style with VectorGlitch!</p>
        </Col>
      </Row>
    </div>
  </Layout>
);

Page.displayName = 'Product Page';
export default Page;

export const pageQuery = graphql`
  query {
    cover: file(relativePath: { eq: "vectorglitch/VectorGlitch-Cover.png" }) {
      ...fluidImage
    }
    before: file(relativePath: { eq: "vectorglitch/VectorGlitch-Before-After.png" }) {
      ...fluidImage
    }
    repeating: file(relativePath: { eq: "vectorglitch/VectorGlitch-Repeating-Letters.png" }) {
      ...fluidImage
    }
    instructions: file(relativePath: { eq: "vectorglitch/VectorGlitch-Instructions-Overview.png" }) {
      ...fluidImage
    }
    swatches: file(relativePath: { eq: "vectorglitch/VectorGlitch-Swatches.png" }) {
      ...fluidImage
    }
    shred: file(relativePath: { eq: "vectorglitch/VectorGlitch-Add-Style.png" }) {
      ...fluidImage
    }
    pure: file(relativePath: { eq: "vectorglitch/VectorGlitch-Pure-Vector.png" }) {
      ...fluidImage
    }
    bonus: file(relativePath: { eq: "vectorglitch/VectorGlitch-Bonus-Font.png" }) {
      ...fluidImage
    }
    thanks: file(relativePath: { eq: "vectorglitch/VectorGlitch-Thanks.png" }) {
      ...fluidImage
    }
  }
`;
